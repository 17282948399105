import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'
import { Link } from 'gatsby'
import { CenteredOnMobile } from '../Hero/styles'
import { ShopPayImage } from '../temp/ToolTip/styles'

export const Section = styled.div`
  position: relative;
  background: linear-gradient(172deg, #e5f0f0 13.11%, #c0dcdc 93.97%);
  padding-bottom: 200px;
  @media (max-width: 1580px) {
    padding-top: 120px;
    padding-bottom: 160px;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding-bottom: 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding-top: 320px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 300px;
  }
`

export const JumbotronImageContainer = styled.div`
  margin-top: 0px;
  > div:first-child {
    display: block;
  }
  > div:nth-child(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin-top: -100px;
    > div:first-child {
      display: none;
    }
    > div:nth-child(2) {
      display: block;
    }
  }
  @media (max-width: 600px) {
    margin-top: -50px;
  }
  @media (max-width: 440px) {
    margin-top: 0px;
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  top: 3%;
  left: 5%;
  width: 90%;
  text-align: center;
`

export const Title = styled.h1`
  color: black;
  font-family: 'FilsonPro';
  font-size: 48px;
  line-height: 111.3%;
  margin: 40px 0px 0px;
  width: 100%;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 38px;
    margin: 8px 0px 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 32px;
    line-height: 112%;
    margin: 0px;
  }
`

export const Text = styled.p`
  color: black;
  font-family: 'FilsonProBook';
  font-size: 22px !important;
  line-height: 140% !important;
  letter-spacing: -0.24px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 8px 0px;
    font-size: 20px !important;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px !important;
  }
`

export const CtaContainer = styled.div`
  display: flex;
`

export const CenteredMobile = styled(CenteredOnMobile)`
  position: relative;
  margin-top: 30px;
  margin: 30px auto 0px;
  @media (max-width: 350px) {
    width: 100%;
  }
`
const linkStyle = css`
  font-family: 'FilsonPro';
  background-color: ${(props) => props.theme.coral};
  color: white;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    letter-spacing: 0.08px;
  }
`
export const BuyNowLink = styled(Link)`
  ${linkStyle}
  color: ${(props) => (props.ctacolor ? 'black' : 'white')};
  background-color: ${(props) => (props.ctacolor ? props.ctacolor : props.theme.coral)};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.ctacolor || props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.ctacolor || props.theme.coral)}`};
  }
  @media (max-width: 1160px) {
    padding: 15px 30px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
    padding: 15px 30px;
  }
  @media (max-width: 350px) {
    display: inline;
    position: relative;
    z-index: 2;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    top: 40px;
  }
  @media (max-width: 450px) {
    top: 0px;
    width: 100%;
    margin: auto;
  }
`
export const ShopPayLogo = styled(ShopPayImage)`
  @media (max-width: ${breakpoints.s}px) {
    width: 240px;
    margin: 16px auto 8px;
  }
`
export const Intro = styled.span`
  font-family: 'FilsonPro';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.484px;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 10px;
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: ${breakpoints.m}px) {
    display: block;
  }
`
