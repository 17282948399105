import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { PopupBox } from '../../Popup/styles'

export const PopupContainer = styled(PopupBox)`
  background-color: #faffff;
  padding: 68px;
  b {
    font-family: 'FilsonProBold';
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 24px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 24px 16px 16px;
  }
`
export const Heading = styled.h2`
  font-family: 'FilsonProMedium';
  font-size: 28px;
  line-height: 120%;
  margin: 0px;
  color: #000;
  width: 350px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
    width: 100%;
  }
`
export const SubHeading = styled.h3`
  font-family: 'FilsonProBook';
  line-height: 140%;
  color: #000;
  margin-bottom: 32px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
    margin-bottom: 16px;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  line-height: 140%;
  color: #000;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
    margin-top: 4px;
  }
`
export const Recommended = styled.h3`
  font-family: 'FilsonProMedium';
  font-size: 20px;
  color: #000;
  margin-bottom: 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 17px;
  }
`
export const ShopPayImage = styled.div`
  width: 240px;
  margin: 24px auto;
  @media (max-width: ${breakpoints.s}px) {
    width: 190px;
    margin: 16px auto 8px;
  }
`
export const CtaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 16px;
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 16px;
    gap: 8px;
  }
`
export const Disclosure = styled(Text)`
  font-size: 14px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 9px;
  }
`
